import Vue from 'vue'
import App from './App.vue'


// 轮播图
import 'swiper/dist/css/swiper.css'

import VueAwesomeSwiper from 'vue-awesome-swiper'

// 引入 axios
import axios from 'axios'
// 挂载一个自定义属性$http
Vue.prototype.$http = axios
// 全局配置axios请求根路径(axios.默认配置.请求根路径)
axios.defaults.baseURL = 'https://admin.kyou-japan.com/index.php/api/cms.Content/'

// import style (<= Swiper 5.x)

Vue.use(VueAwesomeSwiper)

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
