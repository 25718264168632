<template>
  <div class="box">
    <div class="content">
      <div class="left">
        <div>为什么选择</div>
        <div>首页</div>
        <div>公司资讯</div>
        <div>公司简介</div>
      </div>
      <div class="right">
        <div>京合同會社 KYOU CO.,LLC.</div>
        <div>公司設立日 2020年1月8日</div>
        <div>所在地：千葉縣成田市多良貝245-1772</div>
        <div>Tel：04-7689-1045 / 0080-5033-5888</div>
        <div>Fax：E-mail：kyou.japan@gmail.com</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HetongFooter',

  data() {
    return {}
  },

  mounted() {},

  methods: {},
}
</script>

<style lang="scss" scoped>
@media screen and (min-width: 821px) {
  .box {
    width: 100%;
    // height: 260px;
    color: #fff;
    background-color: #000;

    .content {
      display: flex;
      width: 65%;
      margin: 0 auto;
      justify-content: space-between;
      padding: 40px;

      .left {
        width: 100px;
        text-align: left;

        div {
          margin: 15px 0;
        }
      }

      .right {
        width: 300px;
        text-align: left;
        // margin-top: 40px;

        div {
          margin: 10px 0;
        }
      }
    }
  }
}
@media screen and (max-width: 821px) {
  html {
    .box {
      font-size: 10px;
      width: 100%;
      // height: 260px;
      color: #fff;
      background-color: #000;

      .content {
        display: flex;
        width: 100%;
        margin: 0 auto;
        justify-content: space-between;
        padding: 20px 0;

        .left {
          flex: 1;
          width: 100px;
          text-align: center;

          div {
            margin: 15px 0;
          }
        }

        .right {
          flex: 2;
          // float: right;
          width: 300px;
          text-align: left;
          // margin-right: 250px;
          // margin-top: 40px;

          div {
            margin: 10px 0;
          }
        }
      }
    }
  }
}
</style>
