<template>
  <div class="box">
    <div v-if="isShow" class="box-index">
      <div class="head">
        <!-- 头部导航 -->
        <div class="content">
          <!-- logo -->
          <div class="logo">
            <img src="@/images/images/1_03.jpg" alt="" />
          </div>
          <!-- bar -->
          <div class="bar">
            <ul>
              <li v-for="(item, index) in navList" :key="index">
                <span
                  :class="active == index ? 'active' : ''"
                  @click="skipTo(item.id)"
                  >{{ item.title }}</span
                >
              </li>
              <li @click="show">
                简体中文<i class="down-triangle"></i>
                <span class="bar1" v-if="isS">
                  <p>简体中文</p>
                  <p>繁体中文</p>
                </span>
              </li>
            </ul>
          </div>
          <div class="min-bar">
            <span>
              <img
                @click="minShow = !minShow"
                src="@/images/images/1.svg"
                alt=""
              />
            </span>
          </div>
        </div>
      </div>
      <transition name="first">
        <div class="menu" v-if="minShow">
          <ul>
            <li v-for="(item, index) in navList" :key="index">
              <span
                :class="active == index ? 'active' : ''"
                @click="skipTo(item.id)"
                >{{ item.title }}</span
              >
            </li>
            <!-- <transition name="first"> -->
              <li @click="show">
                简体中文<i class="down-triangle"></i>
                <span class="bar1" v-if="isS">
                  <p>简体中文</p>
                  <p>繁体中文</p>
                </span>
              </li>
            <!-- </transition> -->
          </ul>
        </div>
      </transition>
      <Banner id="banner" />
      <div class="main" ref="content">
        <div class="box1 mainBox">
          <div class="title">
            主营业务
            <span>/Main business</span>
            <div class="line"></div>
          </div>
          <div class="content">
            <div class="img">
              <span>
                <img src="@/images/images/1_09.jpg" alt="" />
                <p>输出入贸易</p>
              </span>
              <div class="sj"></div>
              <span>
                <img src="@/images/images/1_11.jpg" alt="" />
                <p>全球进出口</p>
              </span>
              <div class="sj"></div>
              <span>
                <img src="@/images/images/1_13.jpg" alt="" />
                <p>贸易代理</p>
              </span>
              <div class="sj"></div>
              <span>
                <img src="@/images/images/1_15.jpg" alt="" />
                <p>日本海外仓储</p>
              </span>
            </div>
            <div class="text">
              <p>
                日本海外倉儲、企業迷你倉儲業務等
                為主營項目，打通小口貿易物流便利性，
                便海內外顧客國際購物及貿易進出口。
                以價格透明合理化，良心做事誠信做人為基本準則，接待每一位顧客。
              </p>
            </div>
          </div>
        </div>
        <div id="box2" class="box2 mainBox">
          <div class="title">
            为什么选择我们
            <span>/Why choose us?</span>
            <div class="line"></div>
          </div>
          <div class="content">
            <div class="box2-box">
              <img src="@/images/images/1_22.jpg" alt="" />
              <div class="text">
                <div class="box2-title">跨境電商的專業合作夥伴</div>
                <div class="box2-text">
                  提供輸入通關代行，FBA海外倉儲、貼換標、揀貨、捆包、保管等專業，完善、安心的物流服務讓您輕鬆寄、收貨物。
                </div>
              </div>
            </div>
            <div class="box2-box">
              <img src="@/images/images/1_24.jpg" alt="" />
              <div class="text">
                <div class="box2-title">國際輸送物流服務</div>
                <div class="box2-text">
                  且為擴大海外轉送服務的範圍，日本集運轉送提供服務的國家包含美國、英國、新加坡、澳大利亞、德國、東南亞等地區範圍。
                </div>
              </div>
            </div>
            <div class="box2-box">
              <img src="@/images/images/1_26.jpg" alt="" />
              <div class="text">
                <div class="box2-title">輸入供應鏈服務</div>
                <div class="box2-text">
                  提供進出口貿易、大貨物物流方案設計、供應鏈一條龍管理、港到港運輸及報關等專業服務，結合現代物流市場發展趨勢，為客戶提供跳脫傳統、優質、便利的業務。
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="box3" class="box3 mainBox">
          <div class="title">
            最新资讯
            <span>/Latest information</span>
            <div class="line"></div>
          </div>
          <div class="content">
            <div v-for="item in news" :key="item.id">
              <div class="info">
                <div class="date">
                  <span class="top">Jun</span>
                  <span class="bottom">05</span>
                </div>
                <div class="information">
                  <div class="ti">{{ item.title }}</div>
                  <div class="text">
                    {{ item.description }}
                  </div>
                </div>
                <div class="link">
                  <a href="javascript:void(0)" @click="toNews(item.id)"
                    >查看详情>></a
                  >
                </div>
              </div>
              <div class="info-line"></div>
            </div>
          </div>
        </div>
        <div id="box4" class="box4 mainBox">
          <div class="title">
            公司简介
            <span>/Company profile</span>
            <div class="line"></div>
          </div>
          <div class="shadow">
            <div class="content">
              <div class="left">
                <p>
                  日本輸出全球，包裹專線物流服務提供EMS、DHL、郵政航空、海運等物流。由日本專員全球服務、滿足您在日本海淘的便利性，並解決物流問題。（且為擴大海外轉送服務的範圍，日本集運轉送提供服務的國家包含美國、英國、新加坡、澳大利亞、德國、東南亞等地區範圍）
                </p>
              </div>
              <div class="right">
                <img src="@/images/images/1_32.jpg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Partners />
      <Footer />

      <!-- 回到顶部 -->
      <!-- <div class="box-top">
        <img src="@/images/images/top.png" alt="" />
      </div> -->
    </div>
    <div v-else>
      <News @HBack="toBack()" :details="news_details" />
    </div>
  </div>
</template>

<script>
import Banner from '@/view/banner.vue'
// import Main from '@/view/main.vue'
import Partners from '@/view/partners.vue'
import Footer from '@/view/footer.vue'

import News from '@/view/news.vue'

export default {
  name: 'HetongIndex',
  components: {
    Banner,
    // Main,
    Partners,
    Footer,
    News,
  },

  data() {
    return {
      isS: false,

      minShow: false,

      navList: [
        {
          title: '首页',
          id: '#box1',
        },
        {
          title: '为什么选择',
          id: '#box2',
        },
        {
          title: '新闻资讯',
          id: '#box3',
        },
        {
          title: '公司简介',
          id: '#box4',
        },
      ],

      news: [],

      active: 0,
      isShow: true,
      // 新闻详情
      news_details: {},

      // clientHeight: 0,
    }
  },
  mounted() {
    this.getData()
    // 滚动监听
    const _this = this
    document.addEventListener('scroll', function () {
      _this.handleScroll()
    })
  },
  methods: {
    handleScroll() {
      let scrollTop = window.pageYOffset + 100
      // console.dir(window.pageYOffset)
      let blocks = document.querySelectorAll('.mainBox')
      // // console.log(blocks)
      blocks.forEach((item, index) => {
        if (scrollTop >= item.offsetTop) {
          // console.log(index)
          this.active = index
          // console.log(this.active)
        }
      })
    },

    show() {
      this.isS = !this.isS
    },
    //
    tap(index) {
      this.active = index
    },

    toNews(id) {
      this.isShow = false
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
      // console.log(id)
      this.getNews(id)
    },
    toBack() {
      // console.log(2)
      this.isShow = true
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    },

    // 获取文章详情
    async getNews(id) {
      const { data: res } = await this.$http.post('content', { id })
      // console.log(res)
      this.news_details = res.data
    },

    // 获取数据
    async getData() {
      const { data: res } = await this.$http.post('homepage', { type: 2 })
      // console.log(res)
      this.news = res.data
    },

    // 处理数据
    // setData() {

    // },

    // getTop() {
    //   this.navOffsetTop = document.querySelector('.bar').offsetTop
    // },

    // 点击导航跳转到对应锚点
    skipTo(selector) {
      // console.log(selector == '#box1')
      if (selector == '#box1') {
        const target = document.querySelector('#banner')
        target.scrollIntoView({ behavior: 'smooth', block: 'start' })
      } else {
        // const navHeight = document.querySelector('.bar').offsetHeight
        // scrollIntoView() js原生方法，实现锚点滚动过渡
        const target = document.querySelector(selector)
        target.scrollIntoView({ behavior: 'smooth', block: 'start' })
        // scrollTo() 把内容滚动到指定的坐标。减去导航高度的目的：导航用定位固定在顶部，如果不减去，导航栏会遮挡一部分内容
        // window.scrollTo(0, target.offsetTop - navHeight)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@media screen and (min-width: 821px) {
  /* PC端的CSS样式代码 */
  html {
    .box {
      .box-index {
        position: relative;
        top: 90px;
        left: 0;

        .head {
          position: fixed;
          top: 0;
          // left: 0;
          width: 100%;
          height: 90px;
          background: #fff;
          font-size: 14px;
          display: flex;
          justify-content: center;
          z-index: 99;

          .content {
            display: flex;
            justify-content: space-between;
            width: 900px;
            // background: #ccc;

            .logo {
              width: 150px;
              margin-top: 15px;

              img {
                width: auto;
                max-width: 100%;
              }
            }
            .language {
              display: none;
            }
            .min-bar {
              display: none;
            }
            .bar {
              margin-top: 20px;
              z-index: 99;

              ul {
                display: flex;
                justify-content: space-between;

                li {
                  // width: 40px;
                  padding: 4px;
                  margin: 0 30px;
                  list-style: none;
                  cursor: pointer;

                  // a {
                  //   text-decoration: none;
                  // }

                  .active {
                    color: #e10c09;
                    border-bottom: 3px solid #e10c09;
                    padding-bottom: 10px;
                  }

                  &:last-child {
                    position: relative;
                    top: -2px;
                    padding: 10px;
                    margin-right: 10px;
                    text-align: center;
                    line-height: 7px;
                    width: 70px;
                    color: #fff;
                    background: #4872cd;
                    font-weight: normal;
                    cursor: pointer;
                    padding-left: 20px;
                    padding-right: 25px;
                    margin-left: 80px;

                    .bar1 {
                      p {
                        // margin: 18px 0;
                        padding: 5px;
                      }
                    }

                    .down-triangle {
                      position: absolute;
                      top: 10px;
                      right: 9px;
                      // width: 0px;
                      // height: 0px;
                      border-top: 5px solid #fff;
                      border-left: 5px solid transparent;
                      border-right: 5px solid transparent;
                      margin: 0px auto;
                    }
                  }
                }
              }
            }
          }
        }
        .main {
          .title {
            font-size: 35px;
            padding-top: 30px;

            span {
              font-size: 20px;
              color: rgb(130, 130, 130);
              // font-weight: bold;
            }

            .line {
              width: 19%;
              // padding: 1px 0;
              border-bottom: 3px solid #ffe900;
              margin: 15px auto 35px;
            }
          }

          .sj {
            width: 0;
            border: 10px solid transparent;
            border-top: 7px solid transparent;
            border-bottom: 7px solid transparent;
            border-left-color: #2777fc;
            // margin: auto;
            position: relative;
            // top: 10px;
            left: 20px;

            &::after {
              position: absolute;
              display: inline-block;
              content: '';
              width: 6px;
              height: 2px;
              background: #2779fcbe;
              top: 0;
              border-radius: 2px;
              margin: auto;
              bottom: 0;
              left: -20px;
              box-shadow: -8px 0 0 #2779fcbe, -16px 0 0 #2779fcbe,
                -24px 0 0 #2779fcbe;
            }
          }

          .box1 {
            width: 100%;
            height: 500px;
            font-size: 16px;

            .content {
              width: 65%;
              height: 300px;
              // background: #ccc;
              margin: 0 auto;

              .img {
                display: flex;
                width: 100%;
                height: 70%;
                // background: #000;
                align-items: center;
                justify-content: space-evenly;

                span {
                  img {
                    width: 100px;
                    height: 100px;
                  }
                }
              }
            }
          }

          .box2 {
            width: 100%;
            height: 600px;
            background: #e9ecf6;

            .content {
              display: flex;
              width: 65%;
              height: 400px;
              // background: #ccc;
              margin: 0 auto;

              .box2-box {
                width: 30%;
                height: 78%;
                background: #fff;
                border: 1px solid #5c719d;
                margin: auto;

                img {
                  width: 100%;
                }

                .text {
                  .box2-title {
                    font-size: 18px;
                    margin: 10px 0;
                  }

                  .box2-text {
                    font-size: 14px;
                    margin: 10px 0;
                  }
                }
              }
            }
          }

          .box3 {
            width: 100%;
            // height: 500px;
            padding-bottom: 30px;

            .content {
              width: 65%;
              // height: 350px;
              // background: #ccc;
              margin: 0 auto;

              .info {
                display: flex;
                width: 100%;
                height: 100px;
                justify-content: center;
                margin-top: 10px;

                .date {
                  display: flex;
                  flex-direction: column;
                  flex: 1;
                  // text-align: center;
                  justify-content: center;
                  padding-left: 48px;

                  .top {
                    width: 50px;
                    height: 25px;
                    line-height: 25px;
                    color: #fff;
                    background: #4872cd;
                    border: 1px solid #4872cd;
                  }

                  .bottom {
                    font-size: 25px;
                    width: 50px;
                    height: 25px;
                    color: #4872cd;
                    border: 1px solid #4872cd;
                  }
                }

                .information {
                  flex: 7;
                  text-align: center;
                  justify-content: center;
                  width: 70%;
                  margin-top: 15px;

                  .ti {
                    text-align: left;
                    font-size: 20px;
                    font-weight: bold;
                    margin: 10px 0;
                  }

                  .text {
                    width: 100%;
                    font-size: 12px;
                    color: rgb(210, 210, 210);
                    white-space: nowrap;
                    overflow: hidden;
                    text-align: left;
                    text-overflow: ellipsis;
                  }
                }

                .link {
                  flex: 2;
                  margin-top: 58px;
                  a {
                    font-size: 16px;
                    text-decoration: none;
                  }
                }
              }

              .info-line {
                width: 90%;
                margin: 0 auto;
                border-bottom: 3px solid rgb(230, 230, 230);
              }
            }
          }

          .box4 {
            width: 100%;
            height: 500px;
            margin: auto;

            .shadow {
              width: 100%;
              height: 400px;
              background: linear-gradient(to bottom, #fff, rgb(226, 226, 226));
              padding: 20px 0;

              .content {
                display: flex;
                width: 65%;
                // height: 300px;
                color: rgb(130, 130, 130);
                font-size: 18px;
                //
                text-indent: 27px;
                letter-spacing: 3px;
                line-height: 30px;
                background: #fff;
                margin: 0 auto;
                padding: 50px 0;
                box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
                justify-content: space-evenly;
                // align-items: center;

                .left {
                  width: 50%;
                  // margin-top: 40px;
                  // margin-left: 40px;
                  padding: 10px;
                }

                .right {
                  position: relative;
                  width: 40%;
                  // margin-top: 50px;
                  // margin-right: 50px;
                  margin: 0 auto;

                  img {
                    position: absolute;
                    top: 5%;
                    left: 0;
                    width: 98%;
                    height: 90%;
                  }
                }
              }
            }
          }
        }
        .box-top {
          position: fixed;
          right: 50px;
          bottom: 50px;
          width: 100px;
          height: 100px;
          overflow: hidden;
          z-index: 11;

          img {
            width: 50px;
            height: 50px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 821px) {
  /* 移动端的CSS样式代码 */
  html {
    margin: 0;
    padding: 0;
    font-size: 12px;

    .box {
      .head {
        .content {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .logo {
            width: 150px;
            margin-top: 15px;

            img {
              width: auto;
              max-width: 100%;
            }
          }
          .bar {
            display: none;
          }
          .min-bar {
            // display: block;
            width: 60px;
            margin-right: 15px;
            span {
              img {
                width: 70%;
                height: 70%;
              }
            }
          }
        }
      }
      .menu {
        ul {
          margin: 0;
          padding: 0;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          li {
            // width: 40px;
            padding: 4px;
            margin: 10px 0;
            list-style: none;
            cursor: pointer;

            .active {
              color: #e10c09;
              border-bottom: 3px solid #e10c09;
              padding-bottom: 10px;
            }

            &:last-child {
              // position: relative;
              // top: -2px;
              padding: 10px;
              margin-right: 10px;
              text-align: center;
              line-height: 7px;
              width: 70px;
              color: #fff;
              background: #4872cd;
              font-weight: normal;
              margin: 10px auto;
              cursor: pointer;

            }
          }
        }
      }
      .main {
        .title {
          font-size: 30px;
          padding-top: 30px;

          span {
            font-size: 20px;
            color: rgb(130, 130, 130);
            // font-weight: bold;
          }

          .line {
            width: 80%;
            // padding: 1px 0;
            border-bottom: 3px solid #ffe900;
            margin: 15px auto 35px;
          }
        }

        .sj {
          width: 0;
          border: 10px solid transparent;
          border-top: 7px solid transparent;
          border-bottom: 7px solid transparent;
          border-left-color: #2777fc;
          // margin: auto;
          position: relative;
          // top: 10px;
          left: 0px;
          transform: rotate(90deg);

          -ms-transform: rotate(90deg); /* IE 9 */

          -moz-transform: rotate(90deg); /* Firefox */

          -webkit-transform: rotate(90deg); /* Safari 和 Chrome */

          -o-transform: rotate(90deg); /* Opera */

          &::after {
            position: absolute;
            display: inline-block;
            content: '';
            width: 6px;
            height: 2px;
            background: #2779fcbe;
            top: 0;
            border-radius: 2px;
            margin: auto;
            bottom: 0;
            left: -20px;
            box-shadow: -8px 0 0 #2779fcbe, -16px 0 0 #2779fcbe,
              -24px 0 0 #2779fcbe;
          }
        }

        .box1 {
          width: 100%;
          // height: 500px;
          font-size: 16px;

          .content {
            width: 80%;
            // height: 300px;
            // background: #ccc;
            margin: 0 auto;

            .text {
              padding: 10px;
              background: #ccc;
              border-radius: 10px;
              margin-bottom: 20px;
            }

            .img {
              display: flex;
              flex-direction: column;
              width: 100%;
              // height: 70%;
              // background: #000;
              align-items: center;
              justify-content: space-evenly;

              span {
                margin-bottom: 30px;
                img {
                  width: 100px;
                  height: 100px;
                }
              }
            }
          }
        }

        .box2 {
          width: 100%;
          // height: 600px;
          background: #e9ecf6;
          padding-bottom: 20px;

          .content {
            display: flex;
            flex-direction: column;
            width: 80%;
            // height: 400px;
            // background: #ccc;
            margin: 0 auto;

            .box2-box {
              width: 100%;
              // height: 78%;
              background: #fff;
              border: 1px solid #5c719d;
              margin: 10px auto;

              img {
                width: 100%;
              }

              .text {
                .box2-title {
                  font-size: 18px;
                  margin: 10px 0;
                }

                .box2-text {
                  font-size: 14px;
                  margin: 10px 0;
                }
              }
            }
          }
        }

        .box3 {
          width: 100%;
          // height: 500px;
          padding-bottom: 30px;

          .content {
            width: 90%;
            // height: 350px;
            // background: #ccc;
            margin: 0 auto;

            .info {
              display: flex;
              flex-direction: column;
              align-items: center;
              width: 100%;
              // height: 100px;
              justify-content: center;
              margin-top: 10px;

              .date {
                display: flex;
                flex-direction: column;
                flex: 1;
                justify-content: center;

                .top {
                  width: 50px;
                  height: 25px;
                  line-height: 25px;
                  color: #fff;
                  background: #4872cd;
                  border: 1px solid #4872cd;
                }

                .bottom {
                  font-size: 25px;
                  width: 50px;
                  height: 25px;
                  color: #4872cd;
                  border: 1px solid #4872cd;
                }
              }

              .information {
                // flex: 7;
                text-align: center;
                justify-content: center;
                width: 80%;
                // margin-top: 15px;

                .ti {
                  text-align: left;
                  font-size: 18px;
                  font-weight: bold;
                  margin: 10px 0;
                }

                .text {
                  width: 100%;
                  font-size: 10px;
                  color: rgb(210, 210, 210);
                  // white-space: nowrap;
                  display: -webkit-box;
                  /* autoprefixer: off */
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 2;
                  overflow: hidden;
                  text-align: left;
                  text-overflow: ellipsis;
                }
              }

              .link {
                flex: 2;
                // text-align: center;
                // margin-top: 58px;
                padding: 20px 0;
                a {
                  color: #4872cd;
                  font-size: 16px;
                  text-decoration: none;
                }
              }
            }

            .info-line {
              width: 90%;
              margin: 0 auto;
              border-bottom: 3px solid rgb(230, 230, 230);
            }
          }
        }

        .box4 {
          width: 100%;
          // height: 500px;
          // margin: auto;

          .shadow {
            width: 100%;
            // height: 400px;
            background: linear-gradient(to bottom, #fff, rgb(226, 226, 226));
            padding: 20px 0;

            .content {
              display: flex;
              flex-direction: column;
              width: 80%;
              color: rgb(130, 130, 130);
              background: #fff;
              margin: 0 auto;
              padding: 25px 0;
              box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
              justify-content: space-evenly;

              .left {
                text-indent: 27px;
                letter-spacing: 3px;
                line-height: 30px;
              }
              .right {
                img {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }

    // 进入(显示)开始时和离开(隐藏)结束时效果
    .first-enter,
    .first-leave-to {
      // 设置透明度，0表示隐藏，1表示显示，我这里解释的并不是很对，这是css基础知识，大家可以在w3C中查找资料了解一下
      opacity: 0;
    }

    // 进入(显示)过程中和离开(隐藏)过程中的效果
    .first-enter-active,
    .first-leave-active {
      // 设置过渡持续时间
      transition: all 1s;
    }
  }
}
</style>
