<template>
  <div class="content">
    <div class="button" @click="toBack()">&lt;&lt;&nbsp;返回</div>
    <div v-html="details.content"></div>
  </div>
</template>

<script>
export default {
  name: 'HetongNews',

  props: {
    details: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {}
  },

  mounted() {},

  methods: {
    toBack() {
      // console.log(1)
      this.$emit('HBack')
    },
  },
}
</script>

<style lang="scss" scoped>
@media screen and (min-width: 20px) {
  .content {
    position: relative;
    width: 75%;
    height: 500px;
    // background: #ccc;
    margin: 0 auto;
    padding: 75px 0;

    .button {
      position: absolute;
      top: 40px;
      left: 40px;
      color: #fff;
      width: 65px;
      height: 37px;
      line-height: 37px;
      font-size: 14px;
      background: #4872cd;
      border-radius: 5px;
      // background: #ccc;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 20px) {
  .content {
    position: relative;
    // width: 75%;
    // height: 500px;
    // background: #ccc;
    margin: 0 auto;
    padding: 75px 0;

    .button {
      position: absolute;
      top: 40px;
      left: 20px;
      color: #fff;
      width: 65px;
      height: 37px;
      line-height: 37px;
      font-size: 14px;
      background: #4872cd;
      border-radius: 5px;
      // background: #ccc;
      cursor: pointer;
    }
  }
}
</style>
