<template>
  <div>
    <div class="bg">
      <div class="content">
        <div class="title">
          全球物流合作伙伴
          <span>/Global logistics partners</span>
          <div class="line"></div>
        </div>
        <div class="img">
          <img src="@/images/images/1_34.jpg" alt="" />
          <img src="@/images/images/1_36.jpg" alt="" />
          <img src="@/images/images/1_38.jpg" alt="" />
          <img src="@/images/images/1_40.jpg" alt="" />
        </div>
        <div class="text">
          <p>
            提供EMS、DHL、邮政航空、海运等物流。由日本专员全球服务，满足您在日本海淘的便利性，并解决物流问题。
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HetongPartners',

  data() {
    return {}
  },

  mounted() {},

  methods: {},
}
</script>

<style lang="scss" scoped>
@media screen and (min-width: 821px) {
  html {
    .bg {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 750px;
      background: no-repeat center/98% url('@/images/images/1_42.png');

      .content {
        width: 60%;
        // height: 50%;
        background-color: rgba(255, 255, 255, 0.9);

        .title {
          font-size: 35px;
          margin-top: 30px;

          span {
            font-size: 20px;
            color: rgb(130, 130, 130);
            // font-weight: bold;
          }

          .line {
            width: 33%;
            // padding: 1px 0;
            border-bottom: 3px solid #ffe900;
            margin: 15px auto 35px;
          }
        }

        .img {
          margin: 20px 0;

          img {
            width: 150px;
            height: 90px;
            margin: 10px;
          }
        }

        .text {
          font-size: 13px;
          font-weight: bold;
        }
      }
    }
  }
}

@media screen and (max-width: 821px) {
  html {
    margin: 0;
    padding: 0;
    .bg {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      // height: 750px;
      background: no-repeat center/98% url('@/images/images/1_42.png');
      padding-bottom: 25px;

      .content {
        width: 80%;
        // height: 50%;
        background-color: rgba(255, 255, 255, 0.5);

        .title {
          font-size: 30px;
          margin-top: 30px;

          span {
            font-size: 20px;
            color: rgb(130, 130, 130);
            // font-weight: bold;
          }

          .line {
            width: 80%;
            // padding: 1px 0;
            border-bottom: 3px solid #ffe900;
            margin: 15px auto 35px;
          }
        }

        .img {
          margin: 20px 0;

          img {
            width: 40%;
            // height: 90px;
            margin: 10px;
          }
        }

        .text {
          font-size: 13px;
          font-weight: bold;
        }
      }
    }
  }
}
</style>
