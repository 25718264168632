<!-- The ref attr used to find the swiper instance -->
<template>
  <swiper
    :options="swiperOption"
    :not-next-tick="notNextTick"
    ref="mySwiper"
    class="swiper"
  >
    <!-- slides -->
    <swiper-slide v-for="item in swipers" :key="item.id">
      <img :src="item.img_url" alt="" />
    </swiper-slide>
    <!-- <swiper-slide>
      <img src="../images/images/1_06.jpg" alt="" />
    </swiper-slide> -->
    <!-- Optional controls -->
    <!-- 分页点 -->
    <div class="swiper-pagination" slot="pagination"></div>
    <!-- 左右箭头 -->
    <div class="left swiper-button-prev" slot="button-prev"></div>
    <div class="right swiper-button-next" slot="button-next"></div>
    <!-- 滚动条 -->
    <!-- <div class="swiper-scrollbar" slot="scrollbar"></div> -->
  </swiper>
</template>

<script>
// swiper options example:
export default {
  name: 'HetongBanner',
  data() {
    return {
      // 轮播图数据
      swipers: [],
      // NotNextTick is a component's own property, and if notNextTick is set to true, the component will not instantiate the swiper through NextTick, which means you can get the swiper object the first time (if you need to use the get swiper object to do what Things, then this property must be true)
      // notNextTick是一个组件自有属性，如果notNextTick设置为true，组件则不会通过NextTick来实例化swiper，也就意味着你可以在第一时间获取到swiper对象，假如你需要刚加载遍使用获取swiper对象来做什么事，那么这个属性一定要是true
      notNextTick: true,
      swiperOption: {
        // swiper options 所有的配置同swiper官方api配置
        autoplay: 5000, // 自动播放的时间间隔
        slidesPerView: 1, //一次显示的数量
        direction: 'horizontal', // 水平轮播
        autoplayStopOnLast: false, // 当切换到最后一个slide时停止自动切换
        // grabCursor: true,
        loop: true,
        setWrapperSize: true,
        autoHeight: true,
        pagination: '.swiper-pagination',
        paginationClickable: true,
        prevButton: '.swiper-button-prev',
        nextButton: '.swiper-button-next',
        // scrollbar: ".swiper-scrollbar", // 滚动条
        // mousewheelControl: true,
        observeParents: true,
        // if you need use plugins in the swiper, you can config in here like this
        // 如果自行设计了插件，那么插件的一些配置相关参数，也应该出现在这个对象中，如下debugger
        debugger: true,
        // swiper callbacks
        // swiper的各种回调函数也可以出现在这个对象中，和swiper官方一样
        // onTransitionStart(swiper) {
        //   console.log(swiper);
        // },
        // more Swiper configs and callbacks...
        // ...
      },
    }
  },
  // you can find current swiper instance object like this, while the notNextTick property value must be true
  // 如果你需要得到当前的swiper对象来做一些事情，你可以像下面这样定义一个方法属性来获取当前的swiper对象，同时notNextTick必须为true
  computed: {
    // swiper() {
    //   return this.$refs.mySwiper.swiper;
    // },
  },
  mounted() {
    // you can use current swiper instance object to do something(swiper methods)
    // 然后你就可以使用当前上下文内的swiper对象去做你想做的事了
    //   console.log('this is current swiper instance object', this.swiper)
    //   this.swiper.slideTo(3, 1000, false)
    this.getSwiper()
  },
  methods: {
    // 获取到轮播图数据
    async getSwiper() {
      const { data: res } = await this.$http.post('homepage', { type: 1 })
      // console.log(res)
      this.swipers = res.data
    },
  },
}
</script>

<style lang="scss" scoped>
/* sw为自己定义的样式 */
@media screen and (min-width: 821px) {
  .swiper {
    width: 100%;
    // height: 100%;
    // height: 500px;
    background: #fff;

    img {
      width: 100%;
      height: 30rem;
    }
  }
}

@media screen and (max-width: 821px) {
  .swiper {
    width: 100%;
    // height: 100%;
    // height: 500px;
    background: #fff;

    img {
      width: 100%;
      height: 30rem;
    }

    .left {
      display: none;
    }
    .right {
      display: none;
    }
  }
}
</style>
